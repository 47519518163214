<template>
  <layout class="leaderboard" :loading="loading">
    <div class="row">
      <div class="d-flex col-md-6 col-12 justify-content-between">
        <h2 class="mb-4">Leaderboard</h2>
        <div>
          <b-form-select
            class="balance-head__date-select"
            v-model="date"
            :options="dates"
            @change="changeDate()"
            value-field="id"
            text-field="number"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="card">
          <div class="card-body p-0">
            <table class="table">
              <thead>
              <th style="width: 50px">#</th>
              <th class="text-left" style="width: 800px">Name</th>
              <th class="text-left">Score</th>
              </thead>
              <tbody>
              <div style="display: contents;" v-for="(item, index) in list" :key="item.id">
                <tr @click="handleShow(item)">
                  <td><strong>{{ index + 1 }}</strong></td>
                  <td class="text-left">{{ item.employee ? item.employee.name : '' }}</td>
                  <td class="text-right">{{ item.total }}</td>
                </tr>
                <tr v-for="(trans, index) in sortTransaction(item.transactions)" :key="index + item.id" v-show="openRows.some(e => e === item.id)" class="details-leader">
                  <td></td>
                  <td class="text-left">{{ trans.count }} x {{ trans.title }}</td>
                  <td class="text-right">{{ trans.sum }}</td>
                </tr>
              </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>

import moment from 'moment';

/**
 * Editors component *
 */
export default {
  data() {
    return {
      moment,
      leaderboards: [],
      openRows: [],
      date: '2023-09-01 - 2025-01-31',
      dates: [
        {id: '2023-09-01 - 2025-01-31', number: '2023-09-01 - 2025-01-31'},
        {id: '2022-09-01 - 2023-08-31', number: '2022-09-01 - 2023-08-31'}
      ],
    };
  },
  mounted() {
    this.$store.dispatch('profile/getProfile');
    this.changeDate()
  },
  methods: {
    handleShow(item) {
      if (this.openRows.some(e => e === item.id)) {
        this.openRows = this.openRows.filter(e => e !== item.id)
      } else {
        this.openRows.push(item.id)
      }
    },
    changeDate() {
      let payload = {}
      if (this.date !== '') payload.date = this.date

      this.$store.dispatch('leaderboard/getLeaderboard', payload)
    },
    sortTransaction (transactions) {
      const result = Object.entries(transactions).map(item => {
        return item[1]
      })
      const filterOther = result.filter(item => item.title === 'Other')?.['0']
      if (filterOther) {
        const resultFilterWithComment = Object.entries(filterOther).map(item => {
          return item[1]
        }).reduce((acc, cur) => {
          if (cur?.transaction_details) {
            if (acc.length > 0) {
              if (acc.some(e => e.title === cur.transaction_details?.details?.comment)) {
                acc.forEach(item => {
                  if (item.title === cur.transaction_details?.details?.comment) {
                    item.sum = parseInt(item.sum) + parseInt(cur.amount)
                    item.count = item.count += 1
                  }
                })
              } else {
                acc.push({
                  title: cur.transaction_details?.details?.comment,
                  sum: cur.amount,
                  count: 1
                })
              }
            } else {
              acc.push({
                title: cur.transaction_details?.details?.comment,
                sum: cur.amount,
                count: 1
              })
            }
          }
          return acc
        }, [])
        return [...result.filter(item => item.title !== 'Other'), ...resultFilterWithComment]
      }
      return transactions
    }
  },
  computed: {
    list() {
      return this.$store.getters['leaderboard/leaderboardList'];
    },
    loading() {
      return this.$store.state.leaderboard.loading;
    }
  }
};
</script>
<style lang="scss" scoped>
.leaderboard table img {
  max-width: 200px;
}

table, thead, tbody {
  width: 100%;

  th, td {
    font-size: 14px;
    text-align: center;
  }

  tr.details-leader {
    background-color: #f7f7f7;
    border-bottom: 2px solid #ffffff;
  }

  .details-leader td {
    padding: 0 15px;
  }
}

.card-body {
  cursor: pointer;
}
</style>
